<template lang="pug">
span.cl-filters-list
  search(
    data-test="cl-search"
    :value="filters.search"
    @change="setSearchFilter"
    placeholder='Search by name'
  ).cl-filters-list__search
  div.cl-filters-list__status.ml-2
    v-select(
      item-value="key"
      item-text="label"
      hide-details
      :items="statusesList"
      v-model="filters.isArchived"
      label="Status"
      @change="setStatusFilter"
      clearable
      dense
      data-test="cl-status-filter"
    )
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      required: true
    }
  },

  data: () => {
    return {
      statusesList: [
        {
          key: false,
          label: 'Active'
        },
        {
          key: true,
          label: 'Archived'
        }
      ]
    }
  },

  methods: {
    setSearchFilter(value) {
      this.filters.search = value;
      this.$emit('filterChanged');
    },

    setStatusFilter(value) {
      this.filters.isArchived = value;
      this.$emit('filterChanged');
    },
  },

  components: {
    search: () => import('@/components/global/Search.vue')
  }
}
</script>

<style lang="scss" scoped>
.cl-filters-list {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  width: 100%;

  &__search {
    width: 100%;
    transform: translateY(2px);
  }

  &__status {
    min-width: 220px;
    max-width: 220px;
  }
}
</style>
